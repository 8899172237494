/**
 * the panel rotation is set to the FOV when the user open the panel
 * and it keeps that rotation until the user opens the panel again or clicks the chair
 */
AFRAME.registerComponent("ui-rotation-initial-rotate", {
    init: function () {
        this.headEl = document.getElementById("head");
        this.targetRotation = this.headEl.object3D.rotation.clone();

        document.querySelector(".item-bb")?.addEventListener("click", () => {
            this.el.object3D.rotation.copy(this.headEl.object3D.rotation);

            this.el.object3D.rotation.x = 0;
            this.el.object3D.rotation.z = 0;
        });
    },
});
